.ddov_cutome_container{
	width: 1440px;
	max-width: 100%;
}

/* home banner slider style */
.ddov_banner_slider_new {
	overflow-x: hidden;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3, .ddov_home_bg_banner4 {
	width: 100%;
	height: 750px;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-size: cover;
	display: flex !important;
	align-items: center;
	padding-left: 14%;
}
/* .ddov_home_bg_banner4 {background-position: top 100% !important;
} */
.ddov_home_bg_banner1{
	background-image: url('../images/diamond-doves-img/ring-banner.png');
}
.ddov_home_bg_banner2{
	background-image: url('../images/diamond-doves-img/ring-banner1.png');
}
.ddov_home_bg_banner3{
	background-image: url('../images/diamond-doves-img/band-banner.png');
}
/* .ddov_home_bg_banner4{
	background-image: url('../images/diamond-doves-img/book-an-appointment-banner.png');
} */
.ddov_banner_slider_new .slick-prev, .ddov_banner_slider_new .slick-next {
	width: 23px;
height: 45px;
}
.ddov_banner_slider_new .slick-next {
	right: 0;
}
.ddov_banner_slider_new .slick-prev {
	left: 0;
	z-index: 999;
}

/* make an appointment banner */
.ddov_make_An_Appontment_banner{
	background-image: url('../../Assets/images/diamond-doves-img/make-an-appointment.png');
	width: 100%;
	height: 600px;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-size: cover;
}

/* header style */
.ddov_book_an_appontment a span {
	color: #0D0900;
	font-family: 'Inter' !important;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	padding-left: 10px;
}
.ddov_book_an_appontment a img {
	width: 20px;
}
.mg_main_d.ddov_hed_padding {
	padding-top: 25px ;
}

/* shop by shap atyle of diamond doves */
.ddov_shap_outer_box img {
    width: 65px !important;
    height: 65px !important;
    object-fit: cover !important;
    transition: all .3s ease;
}
.ddov_shap_outer_box .item {
	padding: 20px 10px;
}
.ddov_Shop_by_shap_slider .owl-item.active.center img{
	transform: scale(2.5);
	transition: all .3s ease;
}

.ddov_shap_outer_box {
	padding-top: 40px;
}
.ddov_shap_textbox_on_active h3, .ddov_shap_textbox_on_active p{
display: none;
}
.ddov_Shop_by_shap_slider .owl-item.active.center .ddov_shap_textbox_on_active h3, .ddov_Shop_by_shap_slider .owl-item.active.center .ddov_shap_textbox_on_active p{
	display: inline-block;
}
.ddov_Shop_by_shap_slider .owl-carousel .owl-nav.disabled, .ddov_Shop_by_shap_slider .owl-carousel .owl-dots.disabled {
	display: block;
}
.ddov_shap_textbox_on_active h3 {
	font-family: 'Inter' !important;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: #0D0900;
	margin-top: 20px;
}
.ddov_shap_textbox_on_active p {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 12.5px;
	line-height: 24px;
	text-align: center;
	color: #0D0900;
}
.ddov_Shop_by_shap_slider .owl-theme .owl-nav [class*="owl-"], .ddov_Shop_by_shap_slider .owl-theme .owl-next [class*="owl-"]{
	background: none;
	color: #0D0900;
	text-decoration: none;
	transform: translate(0);
	transition: all .3s ease-in-out;
	opacity: .54;
}
.ddov_Shop_by_shap_slider .owl-theme .owl-nav [class*="owl-prev"]:hover {
	background: none;
	color: #000;
	text-decoration: none;
	transform: translate(-10px);
	transition: all .3s ease-in-out;
}
.ddov_Shop_by_shap_slider .owl-theme .owl-nav [class*="owl-next"]:hover {
	background: none;
	color: #000;
	text-decoration: none;
	transform: translate(10px);
	transition: all .3s ease-in-out;
}
.ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-prev, .ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    background: none;
    padding: 0 !important;
    font: inherit;
    position: relative;
    top: -133px;
    font-size: 35px;
    /* margin: 0 100px; */
}

.ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-prev {
	left: -7%;
}
.ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-next {
	right: -7%;
}

/* Engegment Ring Style */
.ddov_eng_ring_section {
    padding: 100px 0 50px;
}
.ddov_btn_gray{
	padding: 10px 25px;
	border: 1px solid rgb(99, 125, 150);
	background-color: rgb(99, 125, 150);
	color: #fff;
	font-size: 18px;
	line-height: 30px;
	font-family: 'Inter' !important;
	border-radius: 0;
	transition: all .3s ease-in-out;
}
.ddov_btn_gray:hover, .ddov_btn_gray:focus, .ddov_btn_gray:active{
	border: 1px solid rgb(99, 125, 150);
	background-color: #fff;
	color: #637D96;
}
.ddov_btn_gray:nth-child(1){
	margin-right: 15px;
}
.ddov_btn_outline{
	padding: 10px 25px;
	border: 1px solid #637D96;
	background-color: #fff;
	color: #637D96;
	font-size: 18px;
	line-height: 30px;
	font-family: 'Inter' !important;
	border-radius: 0;
	transition: all .3s ease-in-out;
}
.ddov_btn_outline:hover, .ddov_btn_outline:focus, .ddov_btn_outline:active{
	border: 1px solid #637D96;
	background-color: #637D96;
	color: #fff;
}
.eng_ring_box img {
	width: 100%;
	height: auto;
}
.ddov_eng_ring_text_box h3, .ddov_eng_ring_text_box h5{
	font-family: 'Libre Baskerville' !important;
}
.ddov_eng_ring_text_box h5 {
	font-weight: 400;
	font-size: 24px;
	line-height: 30px;
	color: #000000;
	margin-bottom: 10px;
}
.ddov_eng_ring_text_box h3 {
	font-weight: 400;
	font-size: 36px;
	line-height: 45px;
	color: #000000;
	margin-bottom: 15px;
}
.ddov_eng_ring_text_box P {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	margin-bottom: 30px;
}

/* About us Style */
.ddov_about_us_section_home {
	padding: 10px 0 80px 0;
}
.ddov_about_text_box {
    position: absolute;
    top: 21%;
    bottom: 0;
    left: 8%;
}
.ddov_about_text_box h2 {
	font-family: 'Libre Baskerville' !important;
	font-weight: 400;
	font-size: 36px;
	line-height: 45px;
	color: #FFFFFF;
	margin-bottom: 20px;
}
.ddov_about_text_box p {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: #FFFFFF;
	width: 45%;
	margin-bottom: 30px;
}
.ddov_about_text_box button {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	color: #FFFFFF;
	padding: 16px 30px;
	border: 1px solid #fff;
	background-color: transparent;
}
.ddov_banner_slider_text p {
	font-size: 18px;
	font-weight: 300;
	padding: 20px 0 33px;
	letter-spacing: 0px;
	margin: 0;
	width: 84%;
}
.ddov_banner_slider_text h1 {
	font-size: 50px;
	text-transform: capitalize;
	margin: 0;
	letter-spacing: 3px;
	font-family: 'Libre Baskerville' !important;
}
.ddov_banner_slider_text button {
	font-size: 18px;
	border-radius: 0;
	padding: 7px 40px;
	font-weight: 400;
	margin: 0;
	background-color: #637D96 !important;
color: #fff !important;
border: 2px solid #637D96 !important;
}
/* Best seller style */
.ddov_best_seller_slider .slick-prev, .ddov_best_seller_slider .slick-next {
    z-index: 999;
    width: 23px;
    height: 45px;
    top: 42%;
}
.ddov_best_seller_text {
	margin-top: 20px;
}
.ddov_best_seller_text h3 {
	font-family: 'Inter' !important;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
	text-align: center;
	color: #0D0900;
	padding: 0 15px;
}
.ddov_best_seller_text p {
	font-family: 'Inter' !important;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	color: #637D96;
}

/* Diamonds gemstones style */
.ddov_diamond_gemstone_section {
	padding: 80px 0;
}
.ddov_text_dia_gem_same  h4{
font-family: 'Libre Baskerville' !important;
font-weight: 400;
font-size: 36px;
line-height: 45px;
text-align: center;
color: #000000;
}

.ddov_text_dia_gem_same p {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	text-align: center;
	color: #0D0900;
	width: 69%;
	margin: 0 auto;
	margin-bottom: 20px;
}
.ddov_text_dia_gem_same a {
	font-family: 'Inter' !important;
	font-weight: 500;
	font-size: 20px;
	line-height: 18px;
	text-align: center;
	color: #637D96;
	letter-spacing: .5px;
}
.ddov_text_dia_gem_same a svg {
	margin-left: 15px;
	font-size: 32px;
	transform: translateX(0);
	transition: all .4s ease-in-out;
}
.ddov_text_dia_gem_same a:hover svg{
	transform: translateX(15px);
	transition: all .4s ease-in-out;
}
.ddov_text_dia_gem_same img {
	margin-bottom: 25px;
}

/* baspoke_jewelery style */
.ddov_baspoke_jewelery_section{
	background-image: url('../images/diamond-doves-img/bespoke-jewellery.png');
	width: 100%;
	height: 400px;
	background-size: cover;
	background-position: 100% 100%;
	background-repeat: no-repeat;
	display: flex;
justify-content: center;
vertical-align: middle;
align-items: center;
margin: 50px 0 0 0;
position: relative;
}
.ddov_baspoke_jewelery_section::before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	content: '';
	background-color: #00000017;
}
.ddov_bj_text h3{
	font-family: 'Libre Baskerville' !important;
font-weight: 400;
font-size: 36px;
line-height: 45px;
text-align: center;
color: #FFFFFF;
}
.ddov_bj_text p {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	color: #FFFFFF;
	width: 36%;
	margin: 20px auto 20px;
}
.ddov_btne_bs_jewel {
	font-family: 'Inter' !important;
	border: 1px solid #FFFFFF;
	color: #fff;
	background-color: transparent;
	padding: 12px 30px;
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
}
/* social style */
.ddov_social_icons {
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
}
.ddov_social_icons_inner svg {
    color: #9ec1e2;
    font-size: 30px;
}
.ddov_social_icons_inner {
	width: 50px;
	height: 50px;
	background-color: #637D96;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10px;
	cursor: pointer;
}
.ddov_insta_section {
	background-color: #fff;
	margin-top: 10px;
}
.ddov_review_out_box {
    padding: 30px 40px 80px;
}
.ddov_google_review {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #b1becb;
    margin-bottom: 20px;
    cursor: pointer;
}
.ddov_google_review img {
	width: 92px;
	height: auto;
	margin: 0 5px;
}
.ddove_review_user img {
	width: 44px;
	height: 44px;
	margin: 5px 15px 0 20px;
}
.ddove_review_user h6 {
	font-weight: 600;
	font-size: 16px;
	line-height: 25px;
	color: #000000;
	font-family: 'inter' !important;
	margin-bottom: 0;
}
.ddove_review_user p {
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	color: #0000008c;
	font-family: 'inter' !important;
	margin-bottom: 0;
}
.white_bg {
	background-color: #fff;
	margin: 50px 10px 0;
	padding: 20px 20px;
	width: 94% !important;
	box-shadow: 0px 0px 3px 0px #ededed;
	border-radius: 5px;
	height: 240px;
	position: relative;
}
.white_bg:before {
    position: absolute;
    bottom: -5px;
    left: 25px;
    content: '';
    width: 20px;
    height: 15px;
    background-color: #fff;
    transform: rotate(-225deg);
    box-shadow: 2px 0px 20px 1px #ccc;
    z-index: -1;
}
.ddove_review_user {
	MARGIN-TOP: 15PX;
	padding-left: 5%;
}
.ddove_btn_book_an_appointment {
    background-color: #fff;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #637D96;
    padding: 15px 30px;
    border: 1px solid #637D96;
    border-radius: 0;
}
/* showroom style  */
.ddov_showrrom_text_box img {
	width: 100%;
	height: auto;
margin: 0 auto;
}
.ddov_shoroom_section {
	background-color: #fff;
	padding: 30px 0 60px;
}
.ddov_showrrom_text_box{
	position: relative;
}
.ddov_showrrom_textbtn_box {
	position: absolute;
	top: 20%;
	left: 5%;
}
.ddov_showrrom_textbtn_box p {
    width: 45%;
    font-family: 'Inter' !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.ddov_showrrom_textbtn_box h2 {
    font-family: 'Libre Baskerville' ! important;
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    color: #FFFFFF;
    margin-bottom: 15px;
}
/**************** Responsive style *****************/
@media(max-width:1024px){
    .ddov_left_side_hed{
        display: none;
    }
}

@media(min-width:768px){
	.ddov_about_img .mobile_img{
		display: none;
	}
	.s_only_mobile{
		display: none;
	}
}

@media(max-width:767px){
	.ddov_about_img .desktop_img{
		display: none;
	}
	.s_only_tablet{
		display: none;
	}
	
}

@media(min-width:1024px){
	.s_only_tablet{
		display: none;
	}
}
@media(max-width:1023px){
.s_only_desktop{
		display: none;
	}
}
@media(min-width:320px) and (max-width:359px){
.ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-prev, .ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	top: -119px !important;
	font-size: 22px !important;
	margin: 0px 55px !important;
}
.ddov_btn_gray, .ddov_btn_outline {
	padding: 10px 9px !important;
	font-size: 12px !important;
	line-height: 20px !important;
}
.ddov_showrrom_textbtn_box h2 {
	font-size: 22px !important;
	line-height: 20px !important;
}
.ddov_showrrom_textbtn_box p {
	width: 95% !important;
	font-size: 11px !important;	
	line-height: 20px !important;
	margin-bottom: 5px !important;
}
.ddove_btn_book_an_appointment {
	font-size: 12px !important;
	line-height: 8px !important;
}
.rcs_mobile_header_menu img {
	margin-top: 0px !important;
}
.rcs_right_side_mobile1 {
	top: 5px !important;
}
.rcs_hero_img h1 {
	font-size: 18px !important;
	letter-spacing: 2px;
	margin-bottom: 0px !important;
	line-height: 0px;
}
.ddov_make_An_Appontment_banner {
	height: 240px !important;
}
}
@media(min-width:414px) and (max-width:512px){
.ddov_showrrom_textbtn_box {
	top: 46% !important;
	left: 35px !important;
}
.ddov_showrrom_textbtn_box p {
	line-height: 24px !important;
	margin-bottom: 15px !important;
}
.ddov_make_An_Appontment_banner {
	height: 300px !important;
}
}

@media(min-width:513px) and (max-width:767px){
.ddov_make_An_Appontment_banner {
	height: 300px !important;
}
}

@media(min-width:320px) and (max-width:767px){
	.eng_ring_box img {
		margin-top: 40px;
	}
	.ddov_make_An_Appontment_banner {
	background-image:url('../../Assets/images/diamond-doves-img/make-an-appointment-mobile.png');
	height: 300px;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3,.ddov_home_bg_banner4  {
	height: 300px;
	padding: 0 30px;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3, .ddov_home_bg_banner4{
	position: relative;
}
.ddov_home_bg_banner1::before, .ddov_home_bg_banner2::before, .ddov_home_bg_banner3::before, .ddov_home_bg_banner4::before{
	position: absolute;
	content: '';
	background-color: #f8f4f4ad;
	top: 0;
    left: 0;
	width: 100%;
	height: 100%;
}
.ddov_banner_slider_text {
	text-align: center;
}
.ddov_banner_slider_text h1 {
	font-size: 24px;
}
.ddov_banner_slider_text button {
	font-size: 14px;
	padding: 7px 30px;
}
.ddov_banner_slider_text p {
	font-size: 14px;
	padding: 20px 0 33px;
	margin: 0 auto;
	width: 100%;
}
	.ddov_shap_outer_box {
    padding-top: 30px;
}
.rcs_ringstyle_title p {
	font-size: 14px;
	margin-bottom: 15px;
}
	.ddov_insta_section {
	margin-top: 0px;
}
.ddov_insta_section p {
	font-size: 13px;
}
	.white_bg {
	background-color: #fff;
	margin: 10px 10px 0;
	padding: 20px 20px;
	width: 94% !important;
	box-shadow: 0px 0px 3px 0px #ededed;
	border-radius: 5px;
	height: 260px;
	position: relative;
}
.ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-prev, .ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	top: -99px;
	font-size: 25px;
	margin: 0px 60px;
}
.ddov_about_text_box p {
	font-size: 15px;
	line-height: 22px;
	width: 98%;
	margin-bottom: 20px;
}
.ddov_about_text_box {
	position: absolute;
	top: 5%;
	left: 20px;
	right: 20px;
}
.ddov_about_text_box button {
	font-size: 16px;
	padding: 10px 25px;
}
.ddov_about_text_box h2 {
	font-size: 24px;
	line-height: 30px;
margin-bottom: 10px;
}
.ddov_btn_gray, .ddov_btn_outline {
	padding: 10px 15px;
	font-size: 14px;
	line-height: 20px;
}
.ddov_text_dia_gem_same {
	margin-bottom: 30px;
}
.ddov_bj_text p {
	line-height: 23px;
	width: 96%;
	margin: 10px auto 20px;
}
.ddov_baspoke_jewelery_section {
	height: 300px;
	background-position: 70% 100%;
	margin: 10px 0;
}
.ddov_diamond_gemstone_section {
	padding: 30px 0;
}
.ddov_gemstones_box_with_img.ddov_text_dia_gem_same{
	margin-bottom: 0;
}
.ddov_text_dia_gem_same p {
	width: 90%;
	margin-bottom: 10px;
}
.ddov_text_dia_gem_same h4 {
	font-size: 24px;
	line-height: 35px;
}
.ddov_text_dia_gem_same a {
	font-size: 16px;
}
.ddov_text_dia_gem_same a svg {
	font-size: 25px;
}
.ddov_bj_text h3 {
	font-size: 24px;
	line-height: 30px;
}
.ddov_eng_ring_text_box h3 {
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 15px;
}
.ddov_eng_ring_text_box h5 {
	font-size: 14px;
	line-height: 30px;
	margin-bottom: 5px;
}
.ddov_eng_ring_text_box P {
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 20px;
}
.ddov_google_review {
	font-size: 18px;
	line-height: 23px;
	margin-bottom: 10px;
}
.ddov_showrrom_textbtn_box h2 {
	font-size: 24px;
	line-height: 25px;
}
.ddov_showrrom_textbtn_box p {
	width: 93%;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 10px;
}
.ddove_btn_book_an_appointment {
	font-size: 14px;
	line-height: 12px;
}
.ddov_showrrom_textbtn_box {
	top: 44%;
	left: 25px;
}
.ddov_social_icons_inner {
	width: 40px;
	height: 40px;
	margin: 0 5px;
}
.ddov_social_icons_inner svg {
	font-size: 20px;
}
.rcs_signup_to_input_field .input-group button {
	font-size: 14px;
	width: 100%;
	margin-top: 15px !important;
	margin-left: 0;
	line-height: 28px;
}
.rcs_signup_to_left_main {
	width: 100%;
	margin: auto;
	padding: 20px 0;
}
.rcs_signup_to_input_field .input-group input {
	width: 100% !important;
}
.ddov_social_icons {
	margin-bottom: 40px;
}
.ddov_review_out_box {
	padding: 0px 0px 20px !important;
}
.rcs_customer_say_inner .slick-prev, .rcs_customer_say_inner .slick-next {
	width: 29px !important;
	height: 20px !important;
}
.ddov_best_seller_slider .slick-prev, .ddov_best_seller_slider .slick-next {
	top: 36%;
}
.mobile_img {
	width: 100% !important;
	height: auto;
}
.ddov_shap_textbox_on_active h3 {
	display: block !important;
	width: 100%;
}
.ddov_eng_ring_section {
	padding: 30px 0;
	padding-top: 0;
}
.ddov_about_us_section_home {
	padding: 10px 0 30px;
}
.ddov_btn_gray:first-child {
	margin-right: 10px;
}
}

@media only screen and (min-width:768px) and (max-width: 1024px)  {
	.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3, .ddov_home_bg_banner4 {
		height: 500px;
		background-position: top right;
	}

	.ddov_eng_ring_section {
		padding-top: 50px !important;
	}
}
@media(min-width:768px) and (max-width:1023px){
	.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3 {
	height: 320px;
	padding-left: 8%;
}
.ddov_banner_slider_text h1 {
	font-size: 25px;
}
.ddov_banner_slider_text button {
	font-size: 14px;
	padding: 7px 30px;
}
.ddov_banner_slider_text p {
	font-size: 14px;
	padding: 15px 0 23px;
	margin: 0;
	width: 60%;
}
	.ddove_review_user {
	padding-left: 8%;
}
	.ddov_make_An_Appontment_banner {
	height: 260px;
}
.ddov_about_text_box h2 {
	font-size: 25px;
	line-height: 22px;
	margin-bottom: 10px;
}
.ddov_about_text_box p {
	font-size: 14px;
	line-height: 22px;
	width: 75%;
	margin-bottom: 10px;
}
.ddov_about_text_box {
	top: 15%;
	bottom: 0;
	left: 8%;
}
.ddov_about_text_box button {
	font-size: 15px;
	line-height: 14px;
	padding: 12px 20px;
}
.ddov_about_us_section_home {
	padding: 20px 0;
}
.ddov_eng_ring_text_box P {
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 20px;
}
.ddov_btn_gray, .ddov_btn_outline {
	padding: 8px 12px;
	font-size: 13px;
	line-height: 20px;
}
.ddov_best_slidew_img {
	width: 92% !important;
	height: auto !important;
	margin: 0 auto !important;
	object-fit: cover;
}
.white_bg {
	margin: 20px 10px 0;
	padding: 10px 10px;
	width: 98% !important;
}
.ddov_review_out_box {
	padding: 30px 30px 50px;
}
.ddove_review_user h6 {
	font-size: 12px;
	line-height: 20px;
}
.ddove_review_user img {
	width: 34px;
	height: 34px;
	margin: 5px 10px 0 20px;
}
.rcs_customer_slide_text_content img {
	margin-top: 13px;
	width: 70px;
}
.rcs_customer_slide_text_content .MuiRating-root.MuiRating-sizeMedium.Mui-readOnly.MuiRating-readOnly.css-ryrseu-MuiRating-root {
	font-size: 16px;
}
.rcs_customer_slide_text_content a {
	font-size: 12px;
	margin-bottom: 15px;
}
.ddove_review_user p {
	font-size: 12px;
}
.rcs_hero_img p {
	font-size: 16px;
}
.rcs_ringstyle_title h2 {
	margin-bottom: 10px;
	font-size: 25px;
}
.ddov_eng_ring_text_box h3 {
	font-size: 25px;
	line-height: 30px;
}
.ddov_eng_ring_text_box h5 {
	font-size: 18px;
	line-height: 25px;
}
.ddov_shap_textbox_on_active h3 {
	font-size: 18px;
	line-height: 25px;
}
.ddov_best_seller_text h3 {
	font-size: 14px;
}
.ddov_best_seller_text p {
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 0;
}
.ddov_diamond_gemstone_section {
	padding: 60px 0 0 0;
}
.ddov_text_dia_gem_same a {
	font-size: 16px;
}
.ddov_text_dia_gem_same h4 {
	font-size: 25px;
	line-height: 35px;
}
.ddov_text_dia_gem_same p {
	line-height: 22px;
	width: 89%;
	margin-bottom: 10px;
}
.ddov_text_dia_gem_same a svg {
	font-size: 26px;
}
.ddov_google_review img {
	width: 62px;
}
.ddov_google_review {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 5px;
}
.ddov_showrrom_textbtn_box h2 {
	font-size: 24px;
	line-height: 22px;
	margin-bottom: 3px;
}
.ddov_showrrom_textbtn_box p {
	width: 46%;
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 15px;
}
.ddove_btn_book_an_appointment {
	font-size: 14px;
	line-height: 14px;
	padding: 12px 20px;
}
.ddov_showrrom_textbtn_box {
	top: 14%;
	left: 7%;
}
.ddov_social_icons_inner {
	width: 40px;
	height: 40px;
	margin: 0 8px;
}
.ddov_social_icons_inner svg {
	font-size: 22px;
}
.rcs_signup_to_input_field .input-group button {
	font-size: 16px;
	margin-left: 20px;
}
.rcs_footer_content_one .logo img {
	width: 55% !important;
	height: 14px !important;
}
ul.rcs_footer_list li a {
	font-size: 14px !important;
}
.gs_footer_content p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
	font-size: 18px !important;
}
.ddov_shap_outer_box {
	padding-top: 30px;
}
.rcs_signup_to_input_field .input-group input {
	height: 45px;
}
.ddov_insta_section {
	margin-top: 10px;
}
.white_bg {
	margin: 20px 10px 0;
	padding: 10px 10px;
	width: 98% !important;
	height: 200px;
}
.ddov_best_seller_sewction {
	padding-top: 30px;
}
.ddov_eng_ring_section {
	padding-top: 0;
	padding-bottom: 50px;
}
}
@media(min-width:1024px) and (max-width:1149px){
	.ddov_make_An_Appontment_banner {
	height: 320px;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3 {
	height: 390px;
	padding-left: 8%;
}
.ddov_banner_slider_text h1 {
	font-size: 25px;
}
.ddov_banner_slider_text button {
	font-size: 14px;
	padding: 7px 30px;
}
.ddov_banner_slider_text p {
	font-size: 14px;
	padding: 15px 0 23px;
	margin: 0;
	width: 60%;
}
}
@media(min-width:1024px) and (max-width:1199px){
.ddov_about_text_box p {
	font-size: 14px;
	line-height: 23px;
	width: 61%;
	margin-bottom: 20px;
}
.ddov_about_text_box {
	top: 15%;
	bottom: 0;
	left: 8%;
}
.ddov_best_slidew_img {
	width: 92% !important;
	height: auto !important;
	margin: 0 auto !important;
	object-fit: cover;
}
.ddov_best_seller_text h3 {
	font-size: 13px;
}
.ddov_best_seller_text p {
	font-size: 13px;
}
.ddov_about_text_box h2 {
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 10px;
}
.ddov_about_text_box button {
	font-size: 16px;
	line-height: 18px;
	padding: 13px 30px;
}
.ddov_eng_ring_text_box h3 {
	font-size: 25px;
	line-height: 30px;
	margin-bottom: 15px;
}
.ddov_eng_ring_text_box h5 {
	font-size: 18px;
	line-height: 23px;
	margin-bottom: 10px;
}
.rcs_ringstyle_title h2 {
	font-size: 25px;
}
.ddov_text_dia_gem_same h4 {
	font-size: 25px;
	line-height: 30px;
}
.ddov_text_dia_gem_same a {
	font-size: 18px;
	line-height: 18px;
}
.ddov_text_dia_gem_same a svg {
	margin-left: 15px;
	font-size: 28px;
}
.ddov_diamond_gemstone_section {
	padding: 40px 0;
}
.ddov_baspoke_jewelery_section {
	height: 230px;
}
.ddov_bj_text h3 {
	font-size: 25px;
	line-height: 25px;
}
.ddov_bj_text p {
	font-size: 14px;
	line-height: 25px;
	margin: 15px auto 15px;
}
.rcs_ringstyle_title p {
	font-size: 14px;
}
.ddov_eng_ring_text_box P {
	font-size: 14px;
	margin-bottom: 25px;
}
.ddov_btn_gray, .ddov_btn_outline{
	font-size: 16px;
}
.ddov_google_review img {
	width: 82px;
}
.ddov_google_review {
	font-size: 18px;
	line-height: 23px;
	margin-bottom: 5px;
}
.ddov_showrrom_textbtn_box h2 {
	font-size: 25px;
	line-height: 25px;
	margin-bottom: 10px;
}
.ddov_showrrom_textbtn_box p {
	width: 47%;
	font-size: 14px;
	line-height: 23px;
	margin-bottom: 15px;
}
.ddove_btn_book_an_appointment {
	font-size: 14px;
	padding: 11px 20px;
}
.ddov_showrrom_textbtn_box {
	top: 14%;
	left: 5%;
}
.rcs_ringstyle.rcs_ringstyle_v2 {
	padding: 30px 0px 0;
}
.ddove_review_user h6 {
	font-size: 14px;
	line-height: 23px;
}
.ddove_review_user p {
	font-size: 12px;
}
.ddov_social_icons_inner {
	width: 40px;
	height: 40px;
}
.ddov_social_icons_inner svg {
	font-size: 22px;
}
.logo img {
	width: 50%;
	height: auto;
}
.rcs_footer_content h2 {
	font-size: 18px;
}
.rcs_footer_content.ddove_footer_get_in_touch {
	padding-left: 22%;
}
.rcs_footer_content.rcs_info.ddove_footer_collection {
	padding-left: 40%;
}
ul.rcs_footer_list li {
	font-size: 12px;
}
ul.rcs_footer_list li a {
	font-size: 12px;
}
.d-flex.footer_get_in_icon_box svg {
	margin-right: 10px;
	font-size: 18px;
}
.ddov_insta_section {
	margin-top: 10px;
}
.rcs_signup_to_left_main {
	width: 85%;
	padding: 45px 0;
}
.rcs_footer_bottom p {
	font-size: 13px;
}
.rcs_custom_home_container.ddov_cutome_container {
	width: 1440px;
	max-width: 100%;
}
.white_bg {
	margin: 20px 10px 0;
	padding: 20px 20px;
	height: 220px;
}
.rcs_customer_say_inner .slick-prev, .rcs_customer_say_inner .slick-next {
	width: 26px !important;
	height: 21px !important;
}
.rcs_hero_img h1 {
	font-size: 25px;
	letter-spacing: 2px;
}
.rcs_hero_img p {
	font-size: 16px;
padding: 10px 0 12px;
	margin: 0;
}
.rcs_hero_img button {
	font-size: 13px;
	padding: 8px 25px;
}
}
@media(min-width:1150px) and (max-width:1350px){
	.ddov_make_An_Appontment_banner {
	height: 400px;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3 {
	width: 100%;
	height: 500px;
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-size: cover;
	/* position: relative; */
	display: flex !important;
	/* justify-content: center; */
	/* vertical-align: middle; */
	align-items: center;
	padding-left: 14%;
}
}
@media(min-width:1351px) and (max-width:1440px){
	.ddov_make_An_Appontment_banner {
	height: 450px;
	padding-left: 10%;
}
}
@media(min-width:1200px) and (max-width:1440px){
	.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3, .ddov_home_bg_banner4 {
	height: 500px;
}
.ddov_banner_slider_text h1 {
	font-size: 35px;
	
}
.ddov_banner_slider_text p {
	width: 70%;
	font-size: 14px;
	padding: 20px 0 23px;
}
.ddov_banner_slider_text button {
	font-size: 16px;
	padding: 5px 15px;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3 {
	height: 500px;
	padding-left: 10%;
}
		.ddov_cutome_container {
	width: 1150px;
	max-width: 100%;
}
    .ddov_book_an_appontment a span {
	font-size: 12px;
	padding-left: 8px;
}
.ddov_book_an_appontment a img {
	width: 18px;
}
.ddov_shap_textbox_on_active p {
	font-family: 'Inter' !important;
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
}
.ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-prev, .ddov_Shop_by_shap_slider .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
	top: -108px;
	font-size: 25px;
}
.ddov_about_text_box p {
	font-size: 14px;
	line-height: 22px;
	width: 48%;
	margin-bottom: 20px;
}
.ddov_about_text_box button {
	font-size: 14px;
	line-height: 15px;
	padding: 8px 25px;
}
.ddov_about_text_box {
	top: 16%;
	bottom: 0;
	left: 6%;
}
.ddov_best_slidew_img{
	width: 95% !important;
	height: auto !important;
	margin: 0 auto !important;
	object-fit: cover;
}
.ddov_best_seller_text h3 {
	font-size: 13px;
	line-height: 20px;
}
.ddov_best_seller_text p {
	font-size: 14px;
	line-height: 14px;
}
.ddov_best_seller_slider .slick-prev, .ddov_best_seller_slider .slick-next {
	width: 14px;
	height: 29px;
	top: 37%;
}
.ddov_baspoke_jewelery_section {
	height: 260px;
	margin: 30px 0;
}
.ddov_bj_text h3 {
	font-size: 30px;
	line-height: 25px;
	margin-bottom: 10px;
}
.ddov_bj_text p {
	font-size: 14px;
	width: 32%;
	line-height: 22px;
}
.ddov_btne_bs_jewel {
	padding: 10px 25px;
	font-size: 16px;
	line-height: 18px;
}
.ddov_eng_ring_text_box h3 {
	font-size: 30px;
	line-height: 35px;
}
.rcs_hero_img h1 {
	font-size: 35px;
}
.rcs_ringstyle_title p {
	font-size: 14px;
	margin-bottom: 15px;
}
.ddov_shap_textbox_on_active h3 {
	font-size: 18px;
	line-height: 22px;
	margin-top: 10px;
}
.ddov_eng_ring_text_box P {
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 20px;
}
.ddov_eng_ring_text_box h5 {
	font-size: 18px;
	line-height: 25px;
	margin-bottom: 10px;
}
.ddov_btn_gray, .ddov_btn_outline {
	padding: 7px 25px;
	font-size: 16px;
}
.ddov_about_text_box h2 {
	font-size: 30px;
	line-height: 35px;
	margin-bottom: 10px;
}
.ddov_text_dia_gem_same h4 {
	font-size: 30px;
	line-height: 30px;
}
.ddov_text_dia_gem_same p {
	font-size: 14px;
	line-height: 23px;
	width: 86%;
	margin-bottom: 10px;
}
.ddov_text_dia_gem_same a {
	font-size: 16px;
	line-height: 18px;
}
.ddov_text_dia_gem_same a svg {
	font-size: 22px;
}
.ddov_diamond_gemstone_section {
    padding: 30px 0 50px 0;
}
.ddov_google_review img {
	width: 60px;
}
section.rcs_customer_say_section {
    padding-top: 35px;
}
.ddov_review_out_box {
    padding: 30px 40px 10px;
}
.ddov_google_review {
	font-size: 18px;
	line-height: 29px;
	margin-bottom: 5px;
}
.white_bg {
	margin: 10px 10px 0;
	padding: 10px 15px;
	width: 97% !important;
	height: 190px;
}
.ddove_review_user img {
	width: 34px;
	height: 34px;
}
.ddove_review_user h6 {
	font-size: 14px;
	line-height: 23px;
}
.ddove_review_user p {
	font-size: 12px;
}
.ddov_showrrom_textbtn_box h2 {
	font-size: 30px;
	line-height: 25px;
}
.ddov_showrrom_textbtn_box p {
	width: 46%;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 15px;
}
.rcs_customer_say_inner .slick-prev, .rcs_customer_say_inner .slick-next {
	width: 24px !important;
	height: 18px !important;
}
.ddove_btn_book_an_appointment {
	font-size: 14px;
	line-height: 11px;
	padding: 15px 30px;
}
.ddov_showrrom_textbtn_box {
	top: 19%;
	left: 6%;
}
.rcs_customer_say_title p {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
}
.ddov_social_icons_inner {
	width: 40px;
	height: 40px;
	margin: 0 5px;
}
.ddov_social_icons_inner svg {
	font-size: 20px;
}
.rcs_signup_to_left_main {
	width: 78%;
	margin: auto;
	padding: 60px 0;
}
.d-flex.footer_get_in_icon_box svg {
	font-size: 16px;
}
.rcs_footer_bottom p {
	font-size: 12px;
}
}
@media(min-width:1441px) and (max-width:1900px){
.ddov_cutome_container {
	width: 1350px;
	max-width: 100%;
}
.ddov_banner_slider_text h1 {
	font-size: 35px;
}
.ddov_best_seller_slider .slick-slide .ddov_best_slidew_img{
	width: 95%;
	height: auto;
}
.ddov_banner_slider_text p {
	width: 70%;
	font-size: 14px;
	padding: 20px 0 23px;
}
.ddov_home_bg_banner1, .ddov_home_bg_banner2, .ddov_home_bg_banner3 {
	height: 610px;
	padding-left: 12%;
}
.ddov_banner_slider_text button {
	font-size: 12px;
	padding: 4px 15px;
}

}
@media(min-width:1150px) and (max-width:1199px){
	.ddov_cutome_container {
	width: 1150px;
	max-width: 100%;
}
}

@media(min-width:1441px) and (max-width:1600px){
.ddov_make_An_Appontment_banner {
	height: 500px;
}
}
@media(min-width:1601px) and (max-width:1900px){
.ddov_make_An_Appontment_banner {
	height: 550px;
}
}

